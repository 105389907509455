<template>
  <div
    id="app"
    :dir="$lang.locale == 'en' ? 'ltr' : 'rtl'"
    :class="{ eng: $lang.locale == 'en' }"
  >
<div class="mainPopopWrap" :class="{ thisYearPopupOpen }">
      <div class="mainPopop expresivi blue w660" >
        <div class="relative">
          <button @click="thisYearPopupOpen = false">✕</button>
          <p class="PopupTitle">
            בשל התגייסות צוות העמותה למילואים אנו נאלצים השנה להקפיא את פעילות עמותת
            דם המכבים
          </p>
          <br />
          <p class="txt-light">
            שנה הבאה נחזור לפעילות הקטיף, אריזה, חלוקה, התרמה, ענידה, זיכרון וצמיחה
          </p>
          <br />
          
          <p class="red">באהבה ותודה על ההבנה,</p>
          <p>צוות דם המכבים</p>
          <img class="popupLogo" src="../src/assets/dam.png" alt="עמותת דם המכבים" />
        </div>
        <img class="popupBg" src="../src/assets/boots-bg.jpg" alt="">
      </div>
</div>
    <topnav v-if="!emptyTemplates" />
    <router-view />
    <botfooter v-if="!emptyTemplates" />
  </div>
</template>

<script>
import topnav from "./components/topnav.vue";
import botfooter from "./components/botfooter.vue";
export default {
  data() {
    return {
      thisYearPopupOpen: true,
    };
  },
  name: "App",
  components: { topnav, botfooter },
  computed: {
    emptyTemplates() {
      return ["/cancel", "/success", "/admin"].find((p) =>
        this.$route.path.match(p)
      );
    },
  },
  methods: {
    openPopup() {
      this.thisYearPopupOpen = true;
    },
  },
};
</script>

<style src="./main.css"></style>
<style>
.mainPopopWrap{
  position: fixed;
  inset: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: all 0.5s ease;
  pointer-events: none;
}
.mainPopopWrap.thisYearPopupOpen {
  opacity: 1;
  pointer-events: all;
}
.mainPopop {
  position: relative;
  background: white;
  font-size: 30px;
  text-align: center;
  padding: 3rem;
  padding-inline-end: 12rem;
  border-radius: 2rem;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.popupLogo {
  width: 150px;
}
.mainPopop p {
  margin: 0;
}
.txt-light {
  font-weight: 400;
}
.mainPopop button {
  position: absolute;
  top: -2rem;
  inset-inline-start: -2rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  background: white;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  transition: all 0.3s ease;
}
.mainPopop button:hover {
  filter: brightness(0.9);
}
.mainPopop button:active {
  transform: scale(0.8);
}
.mainPopop button:focus {
  outline: none;
}
.popupBg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: contain;
  object-position: left;
}
.relative {
  position: relative;
  z-index: 3;
}
.PopupTitle{
  font-size: 2.4rem;
}





@media screen and (max-width: 767px) {
  .mainPopop button {
  top: -1.5rem;
  inset-inline-start: -1.5rem;
  }
.mainPopop {
  font-size: 20px;
  padding: 2rem;
  border-radius: 2rem;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.popupLogo {
  width: 100px;
}

.popupBg{
opacity: 0.25;
}

.PopupTitle{
  font-size: 1.6rem;
}
}
</style>
